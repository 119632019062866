<template>
  <LazyDrawer position="right" :is-open="categoryFiltersTray" :hide-scroll="false">
    <template #header>
      <Stack tag="div" align="start" justify="end">
        <button
          data-test-id="closeBranchTrayButton"
          @click="setCategoryFilters()"
          class="text-charcoal-default justify-self-end"
        >
          <Icon name="plus-thick" :size="16" filled class="text-charcoal-default rotate-45" />
          <p class="sr-only">Close Filters Tray</p>
        </button>
      </Stack>

      <Heading tag="h3" size="xs" class="mt-xs">Filter</Heading>
    </template>

    <div class="pb-[100px] px-2xs">
      <CategoryFilters
        :is-filter-tray="true"
        :facets="facets"
        :total-found-items="categoryBCData.total"
        v-if="categoryBCData && categoryFiltersTray"
        :active-category-id="currentCategoryBrId"
      />
    </div>

    <template #footer>
      <Button
        variant="secondary"
        size="lg"
        class="!w-full !justify-center"
        @click="setCategoryFilters"
        data-fs="filterTrayButton"
      >
        View {{ categoryBCData.total }} products
      </Button>
    </template>
  </LazyDrawer>
</template>

<script lang="ts" setup>
import type { AppliedFilterProps } from "./categoryTypes";

defineProps<AppliedFilterProps>();
const { categoryFiltersTray, setCategoryFilters } = useUIState();

const { data: categoryBCData } = useCategory();

const { currentCategoryBrId } = useCategoryPage();
</script>
